import _ from 'lodash'
import enJson from './locales/en/en.json'
import phJson from './locales/ph/ph.json'
import idJson from './locales/id/id.json'
// import vmJson from './locales/vm/vm.json'

// function getData(language) {
//   console.log("🚀 ~ getData ~ language:", language)
//   const fallbackLng = {
//     en: enJson,
//     ph: phJson,
//     id: idJson,
//   }

//   return new Promise((resolve) => {
//     fetch(
//       `${process.env.GATSBY_TRANSLATIONS_URL}/locales/${language}/${language}.json`,
//       {
//         method: 'GET',
//       },
//     )
//       .then((response) => {
//         if (!response.ok) {
//           throw new Error(`Failed to fetch translations for ${language}`);
//         }
//         return response.json();
//       })
//       .then((data) => {
//         if (data) {
//           resolve(_.merge(fallbackLng[language], data))
//         } else {
//           resolve(fallbackLng[language])
//         }
//       })
//       .catch((error) => {
//         console.error(error);        
//         resolve(fallbackLng[language]);
//       });
//   })
// }

// export default getData;

// The issue in your callback function is that you are not handling the rejection of the Promise returned by the fetch call.
// You should add a catch block after the fetch chain to handle any errors that might occur during the fetch operation.

function getData(language) {  
  const fallbackLng = {
    en: enJson,
    ph: phJson,
    id: idJson,
  }

  return new Promise((resolve, reject) => {
    fetch(
      `${process.env.GATSBY_TRANSLATIONS_URL}/locales/${language}/${language}.json`,
      {
        method: 'GET',
      },
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Failed to fetch translations for ${language}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          resolve(_.merge(fallbackLng[language], data))
        } else {
          resolve(fallbackLng[language])
        }
      })
      .catch((error) => {
        console.log("🚀 ~ returnnewPromise ~ error:", error)
        console.error(error);        
        // reject(error); // Add rejection handling here
        resolve(fallbackLng[language])
      });
  })
}

export default getData;