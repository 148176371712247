import _ from 'lodash'
import { Link } from 'gatsby'
import React from 'react'
import classNames from 'classnames'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { withTrans } from '../../../i18n/withTrans'
import { getRoute } from '../../../helpers'
import ArrowRight from '../../../assets/icons/pacificcross_icons_arrow-right.svg'
import './sub-menu-styles.scss'
import ImgApps from '../../../assets/images/you-matter/pacificcross_navigation_youmatter_02.jpeg'
import Applestore from '../../../assets/icons/pacificcross_icons_app_apple.svg'
import GooglePlay from '../../../assets/icons/pacificcross_icons_app_googleplay.svg'

const ItemApps = ({
  titleMenuSelected,
  submenutitle,
  idx,
  child,
  t,
  key,
}) => (
  <div key={key} className="submenu__app__container">
    <div className="submenu__app__box">
      <div className="submenu__app__image">
        <img src={ImgApps} />
      </div>
      <div className="submenu__app__content">
        <h4>
          {t(
            `menu.${titleMenuSelected}.children.${submenutitle}.subgroup.${[
              idx,
            ]}.children.${child.title}`,
          )}
        </h4>
        <p>{t('menu.apps.description')}</p>
      </div>
    </div>
    <div className="submenu__app__list">
      <a href={child.url.ios} target="_blank" rel="noreferrer">
        <Applestore />
      </a>
      <a href={child.url.android} target="_blank" rel="noreferrer">
        <GooglePlay />
      </a>
    </div>
    <li>
      <span className="arrow-right">
        {t('menu.apps.titleIOS')}
      </span>
    </li>
    <li>
      <span className="arrow-right">
        {t('menu.apps.titleAndroid')}
      </span>
    </li>
  </div>
)

const ItemList = ({
  child,
  menuSelectedItem,
  titleMenuSelected,
  id,
  submenutitle,
  t,
  i18n,
  idx,
  key,
  parentSlug,
}) => {
  const slug = parentSlug ? `/${parentSlug}/${child.slug}/` : `/${child.slug}/`
  const translatedTitle = t(
    `menu.${titleMenuSelected}.children.${submenutitle}.subgroup.${[
      idx,
    ]}.children.${child.title}`,
  );

  if (translatedTitle.toLowerCase() === "dasar" || translatedTitle.toLowerCase() === "basics") {
    return null; // Jangan render komponen jika nilai terjemahan adalah "insurance"
  }

  return (
    <li key={key}>
      {child.url || (id === 'portal-apps' && child.link) ? (
        <a
          href={child.url || child.link}
          target="_blank"
          rel="noreferrer"
          key={idx}
        >
          <span key={child.title}>
            {menuSelectedItem === 0
            || id === 'our-products'
            || id === 'portal-apps'
              ? child.title || child.text
              : t(
                `menu.${titleMenuSelected}.children.${submenutitle}.subgroup.${[
                  idx,
                ]}.children.${child.title}`,
              )}
          </span>
          <span className="arrow-right">
            <ArrowRight width="20" fill="#FF9124" />
          </span>
        </a>
      ) : (
        <Link to={getRoute({
          locale: i18n.language,
          route: slug,
        })}>
          <span key={child.title}>
            {menuSelectedItem === 0 || id === 'our-products'
              ? child.title
              : translatedTitle}
          </span>
          <span className="arrow-right">
            <ArrowRight width="20" fill="#FF9124" />
          </span>
        </Link>
      )}
    </li>
  )
}

const ListWithSubgroup = ({
  m,
  t,
  i18n,
  titleMenuSelected,
  submenutitle,
  menuSelectedItem,
  parentSlug,
  id,
}) => {
  const breakpoints = useBreakpoint()
  const columnSubGroup = classNames({
    row: true,
    submenu__subgroup: true,
    submenu__app: m.id === 'portal-apps',
  })
  const columnSubGroupStyles = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: id === 'our-products' ? 'wrap' : 'nowrap', // Conditionally set flex-wrap
  };

  return (
    <div style={columnSubGroupStyles} className={columnSubGroup}>
      {_.map(m.subgroup, (item, idx) => {
        const listItem = _.compact(_.get(item, 'children', []))
        const columnClass = classNames({
          column: true,
          long__list: listItem.length > 6,
        })
        return (
          <div className="col" key={idx}>
            <h5>
              {menuSelectedItem === 0 || id === 'our-products'
                ? item.title
                : t(
                  `menu.${titleMenuSelected}.children.${submenutitle}.subgroup.${[
                    idx,
                  ]}.title`,
                  { country: process.env.GATSBY_COUNTRY },
                )}
            </h5>
           
            <ul className={columnClass}>
              {_.map(listItem, (child) => {
                if (child.slug === 'how-to-avail') {
                  if (child.title === 'medical-plans') {                    
                    return (
                      <ItemList
                        child={child}
                        key={child.name}
                        menuSelectedItem={menuSelectedItem}
                        titleMenuSelected={titleMenuSelected}
                        id={m.id || id}
                        idx={idx}
                        submenutitle={submenutitle}
                        t={t}
                        i18n={i18n}
                        parentSlug={parentSlug}
                      />
                    );
                  } else {
                    return null; // Don't render the ItemList if the title is not "Medical Plans"
                  }  
                }else{
                  return (
                    // <ItemList
                    //   child={child}
                    //   key={child.name}
                    //   menuSelectedItem={menuSelectedItem}
                    //   titleMenuSelected={titleMenuSelected}
                    //   id={m.id || id}
                    //   idx={idx}
                    //   submenutitle={submenutitle}
                    //   t={t}
                    //   i18n={i18n}
                    //   parentSlug={parentSlug}
                    // />
                    
                      child.title === 'heath-insurace-app-title' ? null : (
                        <ItemList
                          child={child}
                          key={child.name}
                          menuSelectedItem={menuSelectedItem}
                          titleMenuSelected={titleMenuSelected}
                          id={m.id || id}
                          idx={idx}
                          submenutitle={submenutitle}
                          t={t}
                          i18n={i18n}
                          parentSlug={parentSlug}
                        />
                      )
                    
                  );
                }
                
              })}
            </ul>
          </div>
        )
      })}
    </div>
  )
}

export default withTrans(ListWithSubgroup)
